import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import * as styles from './Tag.module.css';
import Link from '@components/Link';

const Tag = ({
  as = 'div',
  children,
  variant = 'default',
  className,
  href,
}) => {
  const T = as;

  const props = {
    className: cn(styles.root, className),
    'data-variant': variant,
  };

  return !href ? (
    <T {...props}>{children}</T>
  ) : (
    <Link href={href} {...props}>
      {children}
    </Link>
  );
};

Tag.propTypes = {
  as: PropTypes.oneOf(['div', 'span', 'p', 'li']),
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'alert', 'highlight']),
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Tag;
